import React from 'react';
import { useAtom } from 'jotai';
import { makeStyles } from '@material-ui/core';
import List from '@material-ui/core/List';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import ListItem from '@material-ui/core/ListItem';

import toggleDrawer from '@/others/toggleDrawer';
import { menuOpenStatus } from '@/others/atom';

import MenuItem from '../basic/MenuItem';
import SNSList from '../middle/SNSList';

const useStyles = makeStyles({
  listStyle: {
    width: 240,
    paddingLeft: `1rem`,
  },
});

const MenuDrawer: React.VFC = () => {
  const [isOpen, setIsOpen] = useAtom(menuOpenStatus);
  const iOS =
    typeof navigator !== `undefined` &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);
  const classes = useStyles();

  return (
    <SwipeableDrawer
      anchor="right"
      open={isOpen}
      onOpen={toggleDrawer(true, setIsOpen)}
      onClose={toggleDrawer(false, setIsOpen)}
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
    >
      <List className={classes.listStyle}>
        <MenuItem name="トップ" linkTo="" />
        <MenuItem name="メッセージ" linkTo="message" />
        <MenuItem name="プロフィール" linkTo="profile" />
        <MenuItem name="6つの政策" linkTo="policies" />
        <ListItem>
          <SNSList />
        </ListItem>
      </List>
    </SwipeableDrawer>
  );
};

export default MenuDrawer;
