import React from 'react';

import IconButton from '@material-ui/core/IconButton';

import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';

const SNSList: React.VFC = () => (
  <div>
    <IconButton
      color="inherit"
      href="https://www.facebook.com/IshinFujikawa/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <FacebookIcon fontSize="default" />
    </IconButton>
    <IconButton
      color="inherit"
      href="https://twitter.com/Ishin_Fujikawa"
      target="_blank"
      rel="noopener noreferrer"
    >
      <TwitterIcon fontSize="default" />
    </IconButton>
    <IconButton
      color="inherit"
      href="https://www.instagram.com/ishin_fujikawa/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <InstagramIcon fontSize="default" />
    </IconButton>
  </div>
);

export default SNSList;
