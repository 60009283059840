import React from 'react';

const toggleDrawer = (
  isOpen: boolean,
  setIsOpen: (update?: React.SetStateAction<boolean>) => void | Promise<void>,
) => (event: React.KeyboardEvent | React.MouseEvent) => {
  if (
    event &&
    event.type === `keydown` &&
    ((event as React.KeyboardEvent).key === `Tab` ||
      (event as React.KeyboardEvent).key === `Shift`)
  ) {
    return;
  }

  setIsOpen(isOpen);
};

export default toggleDrawer;
