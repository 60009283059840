import React from 'react';
import { navigate } from 'gatsby';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

type MenuItemProps = {
  name: string;
  linkTo: string;
};

const MenuItem: React.VFC<MenuItemProps> = ({ name, linkTo }) => {
  const handleClick = () => navigate(`/${linkTo}`);

  return (
    <ListItem button onClick={handleClick} key={name}>
      <ListItemText>{name}</ListItemText>
    </ListItem>
  );
};

export default MenuItem;
