import { createMuiTheme } from '@material-ui/core/styles';
import '@fontsource/noto-sans-jp';
import '@fontsource/noto-serif-jp';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: `#32b59a`,
      main: `#00a381`,
      dark: `#008267`,
      contrastText: `#FFFFFA`,
    },
    secondary: {
      light: `#333335`,
      main: `#000103`,
      dark: `#000002`,
      contrastText: `#FFFFFA`,
    },
    background: {
      default: `#FFFFFA`,
      paper: `#FFFFFc`,
    },
    success: {
      light: `#32b59a`,
      main: `#00a381`,
      dark: `#008267`,
      contrastText: `#FFFFFA`,
    },
    error: {
      light: `#ff5a57`,
      main: `#FF312E`,
      dark: `#cc2724`,
      contrastText: `#000103`,
    },
  },
  overrides: {
    MuiListItemText: {
      secondary: {
        color: `#000103`,
      },
    },
  },
  typography: {
    fontFamily: `Noto Sans JP`,
  },
});

theme.typography.h1 = {
  fontSize: `1.68rem`,
  fontWeight: 600,
  margin: `2rem auto`,
  color: `#ffffff`,
  textShadow: `0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)`,
  [theme.breakpoints.up(`sm`)]: {
    fontSize: `2rem`,
  },
  [theme.breakpoints.up(`lg`)]: {
    fontSize: `2.4rem`,
  },
};

theme.typography.h2 = {
  fontSize: `1.3rem`,
  fontWeight: 600,
  textShadow: `0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)`,
  [theme.breakpoints.up(`sm`)]: {
    fontSize: `1.6rem`,
  },
};

theme.typography.h3 = {
  fontSize: `1.2rem`,
  fontWeight: 600,
  textShadow: `0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)`,
  [theme.breakpoints.up(`lg`)]: {
    fontSize: `1.5rem`,
  },
};

theme.typography.h4 = {
  fontSize: `1.1rem`,
  fontWeight: 600,
};

theme.typography.h5 = {
  fontSize: `1rem`,
  fontWeight: 600,
  [theme.breakpoints.up(`lg`)]: {
    fontSize: `1.1rem`,
  },
};

theme.typography.body1 = {
  fontSize: `1rem`,
  lineHeight: 2,
  margin: `.5rem auto`,
  width: `100%`,
  fontWeight: 400,
};

export default theme;
