import React from 'react';
import { Provider } from 'jotai';

import { ThemeProvider, makeStyles } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';

import theme from '@/others/theme';
import Header from '../common/section/Header';
import Footer from '../common/section/Footer';
import MenuFab from '../common/basic/MenuFab';

type LayoutProps = {
  children: React.ReactNode;
};

const useStyles = makeStyles({
  layoutStyles: {
    display: `flex`,
    flexDirection: `column`,
    minHeight: `100vh`,
  },
});

const Layout: React.VFC<LayoutProps> = ({ children }) => {
  const classes = useStyles();
  return (
    <Provider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className={classes.layoutStyles}>
          <Header />
          <main>{children}</main>
          <MenuFab />
          <Footer />
        </div>
      </ThemeProvider>
    </Provider>
  );
};

export default Layout;
