import React from 'react';
import { useAtom } from 'jotai';

import { makeStyles } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Zoom from '@material-ui/core/Zoom';
import MenuSharpIcon from '@material-ui/icons/MenuSharp';

import { menuOpenStatus } from '@/others/atom';
import toggleDrawer from '@/others/toggleDrawer';

const useStyles = makeStyles({
  contactBox: {
    position: `fixed`,
    bottom: 24,
    right: 24,
  },
  contactLink: {
    textDecoration: `none`,
  },
});

const MenuFab: React.VFC = () => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const [, setIsOpen] = useAtom(menuOpenStatus);
  const classes = useStyles();

  return (
    <Zoom in={trigger}>
      <div className={classes.contactBox}>
        <Fab color="secondary" onClick={toggleDrawer(true, setIsOpen)}>
          <MenuSharpIcon />
        </Fab>
      </div>
    </Zoom>
  );
};

export default MenuFab;
