import React from 'react';
import { Link } from 'gatsby';
import { useAtom } from 'jotai';

import { makeStyles } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import MenuSharpIcon from '@material-ui/icons/MenuSharp';

import { menuOpenStatus } from '@/others/atom';
import toggleDrawer from '@/others/toggleDrawer';

import MenuDrawer from './MenuDrawer';
import SNSList from '../middle/SNSList';

type HeaderProps = {
  children?: React.ReactElement;
};

const useStyles = makeStyles({
  grow: {
    flexGrow: 1,
  },
  toolBar: {
    display: `flex`,
    alignItems: `center`,
    WebkitAlignItems: `center`,
    justifyContent: `space-between`,
    WebklitJustifyContent: `space-between`,
    color: `rgba(0, 0, 0, 0.87)`,
  },
  headerLinkBox: {
    display: `flex`,
    textDecoration: `none`,
    alignItems: `center`,
  },
  headerTitle: {
    color: `rgba(0, 0, 0, 0.87)`,
    lineHeight: 1.43,
    fontWeight: 600,
  },
});

const Header: React.VFC<HeaderProps> = () => {
  const [, setIsOpen] = useAtom(menuOpenStatus);
  const classes = useStyles();

  return (
    <>
      <AppBar color="transparent" position="absolute" elevation={0}>
        <Toolbar className={classes.toolBar}>
          <div>
            <Link to="/" className={classes.headerLinkBox}>
              <Typography variant="h4" className={classes.headerTitle}>
                藤川たかし公式HP
              </Typography>
            </Link>
          </div>
          <div className={classes.grow} />
          <Hidden xsDown>
            <SNSList />
          </Hidden>
          <IconButton onClick={toggleDrawer(true, setIsOpen)}>
            <MenuSharpIcon htmlColor="rgba(0, 0, 0, 0.87)" />
          </IconButton>
        </Toolbar>
      </AppBar>
      <MenuDrawer />
    </>
  );
};

export default Header;
