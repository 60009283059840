import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footerStyle: {
      padding: `1rem`,
      background: theme.palette.primary.main,
      fontSize: 12,
      textAlign: `center`,
      color: `#ffffff`,
      marginTop: `auto`,
    },
  }),
);

const Footer: React.VFC = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footerStyle}>
      © {new Date().getFullYear()} 藤川たかし all rights reserved.
    </footer>
  );
};

export default Footer;
